import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class ScrollDownButton extends React.Component{
    render(){
        return(
            <AnchorLink offset="80" href="#three-step-process" className="c_scroll-down-button">SCROLL DOWN</AnchorLink>
        )
    }
}

export default ScrollDownButton