import c2c from '../apis/admin'

import {
    SUBMIT_CONTACT_FORM,
    SUBMIT_CONTACT_FORM_FAILED,
    RESET_SUBMIT_CONTACT_FORM_STATE
} from './types'

export const submitContactForm = formValues => async dispatch => {
    console.log(formValues)
    try{

        const response = await c2c.post('send-contact-email', formValues)

        dispatch({
            type: SUBMIT_CONTACT_FORM,
            payload: response.data
        })

        // window.location = "/thankyou"

    } catch(error){

        dispatch({
            type: SUBMIT_CONTACT_FORM_FAILED,
            payload: error
        })

    }
}

export const resetSubmitContactForm = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_SUBMIT_CONTACT_FORM_STATE,
        })
    }
}