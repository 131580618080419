import React from 'react'
import { connect } from 'react-redux'
import { 
    submitContactForm,
    resetSubmitContactForm
} from '../../actions'
import {
    Field,
    reduxForm
} from 'redux-form'
import {
    Redirect
} from 'react-router-dom'

class ContactForm extends React.Component{

    state = {
        submitted: false,
        error: false
    }

    componentDidUpdate(){
        if(this.props.sendContactMail.success === false){
            this.props.resetSubmitContactForm()
            this.setState({
                submitted: false,
                error: true
            })
        }
    }

    componentWillUnmount(){
        this.props.resetSubmitContactForm()
    }

    renderError({error, touched}){
		if(error && touched){
			return(
                <div className="c_error-message" role="alert">
                    {error}
                </div>
			)
		}
	}

    renderInput = ({input, label, meta, type}) => {
        const className = `c_form-group c_form-group-6 ${meta.error && meta.touched ? 'c_error' : ''}`

        return (
            <div className={className}>
                <label>{label}</label>
                <input type={type} className="c_form-control" {...input} autoComplete="off" placeholder="Type here" />
                {this.renderError(meta)}
            </div>
        )
    }

    renderTextArea = ({input, label, meta}) => {
        const className = `c_form-group c_form-group-12 ${meta.error && meta.touched ? 'c_error' : ''}`
        return (
            <div className={className}>
                <label>{label}</label>
                <textarea className="c_form-control" {...input} placeholder="Type here" rows="3"></textarea>
                {this.renderError(meta)}
            </div>
        )
    }

    renderSubmitButton = () => {
        if(this.state.submitted){
            return <p className="c_message-sending-message">Message sending. Please wait...</p>
        } else {
            return <button className="c_button">Submit</button>
        }
    }

    onSubmit = formValues => {
        this.setState({
            submitted: true,
            error: false
        })
        this.props.submitContactForm(formValues)
    }

    render(){

        if(this.props.sendContactMail.success === true)
            return <Redirect to="thankyou" />
        
        return(
            <>
                
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="c_form">
                    <Field name="first_name" type="text" component={this.renderInput} label="First Name*" />
                    <Field name="last_name" type="text" component={this.renderInput} label="Last Name*" />
                    <Field name="email" type="email" component={this.renderInput} label="Email*" />
                    <Field name="contact" type="text" component={this.renderInput} label="Contact Number*" />
                    <Field name="message" component={this.renderTextArea} label="Message*" />
                    {this.state.error && <p className="c_error-message">Message could not be sent. Please try again.</p>}
                    {this.renderSubmitButton()}
                </form>
            </>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.first_name){
        errors.first_name = "You must enter your first name"
    }

    if(!formValues.last_name){
        errors.last_name = "You must enter your last name"
    }

    if(!formValues.email){
        errors.email = "You must enter your e-mail address"
    } else if(!formValues.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        errors.email = "You must enter a valid e-mail address"
    }

    if(!formValues.contact){
        errors.contact = "You must enter your contact number"
    } else if(!formValues.contact.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
        errors.contact = "You must enter a valid contact number"
    }

    if(!formValues.message){
        errors.message = "Please enter a message"
    }

    return errors
}

const mapStateToProps = state => {
    return {
        sendContactMail: state.contactFormState
    }
}

const connectedComponent = connect(mapStateToProps, {
    submitContactForm,
    resetSubmitContactForm
})(ContactForm)

export default reduxForm({
    form: 'contact',
    validate
})(connectedComponent)