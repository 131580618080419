import React from 'react'

class Container extends React.Component{
    render(){
        return(
            <div id={this.props.id ? this.props.id : ''} className={`c_container ${this.props.className ? this.props.className : ''}`}>
                {this.props.children}
            </div>
        )
    }
}

export default Container