import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class SrollToTopButton extends React.Component{

    state = {
        show: false
    }

    componentDidMount(){
        window.addEventListener("scroll", () => {
            let height = window.innerHeight - 190
            if(window.scrollY > height){
                if(!this.state.show){
                    this.setState({
                        show: true
                    })
                }
                
            } else {
                if(this.state.show){
                    this.setState({
                        show: false
                    })
                }
            }
        })
    }

    render(){
        return this.state.show ? <AnchorLink href="#root" className="c_scroll-to-top-button"></AnchorLink> : <></>
    }
}

export default SrollToTopButton