import React from 'react'
import logo from '../../assets/img/ctc-logo@2x.png'
import SrollToTopButton from '../ui/SrollToTopButton'

class Footer extends React.Component{

    render(){
        return(
            <footer className="c_footer">
                <div className="c_inner">
                    <div className="c_column c_column_1">
                        <img className="c_logo" src={logo} alt="" />
                    </div>
                    <div className="c_column c_column_2">
                        <h3 className="c_footer-heading">ABOUT US</h3>
                        <p className="c_footer-par">Crown to Cork is a service provider for the ever-growing Cap Classique sector in the South African wine industry.</p>
                    </div>
                    <div className="c_column c_column_3">
                        <h3 className="c_footer-heading">CONTACT US</h3>
                        <p className="c_footer-par">
                            <strong>Email:</strong> <a href="mailto:info@crowntocork.co.za">info@crowntocork.co.za</a><br />
                            <strong>Telephone:</strong> <a href="tel:021 300 0887">021 300 0887</a><br />
                            <strong>Address:</strong> <a href="https://goo.gl/maps/PrniKPoVDYBFZu8BA" target="_blank" rel="noreferrer">
                                Buitepost Warehouse, Simonsberg Business Park, Klapmuts, Cape Town.
                            </a>
                        </p>
                    </div>
                </div>
                <SrollToTopButton />
            </footer>
        )
    }
}

export default Footer