import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Home from './components/pages/Home'
import Thankyou from './components/pages/Thankyou'
import PageNotFound from './components/pages/PageNotFound'

class App extends React.Component {
  render(){
    return(
      <Router>
        
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/thankyou" component={Thankyou} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    )
  }
}

export default App