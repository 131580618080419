import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class WidescreenNav extends React.Component{

    render(){
        return(
            <nav className="c_widescreen-nav">
                <ul>
                    <li>
                        <AnchorLink offset={95} href="#riddling">RIDDLING</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink offset={95} href="#disgorgement">DISGORGEMENT</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink offset={95} href="#dosage">DOSAGE</AnchorLink>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default WidescreenNav