import {
    SUBMIT_CONTACT_FORM,
    SUBMIT_CONTACT_FORM_FAILED,
    RESET_SUBMIT_CONTACT_FORM_STATE
} from '../actions/types'

const contactFormReducer = (state = {}, action) => {    
    switch (action.type){
        case SUBMIT_CONTACT_FORM:
            return {
                ...state,
                success: true
            }
        case SUBMIT_CONTACT_FORM_FAILED:
            return {
                ...state,
                success: false
            }
        case RESET_SUBMIT_CONTACT_FORM_STATE:
            return {}
        default:
            return state
    }
}

export default contactFormReducer