import React from 'react'
import HamburgerIcon from './HamburgerIcon'
import ContactList from './ContactList'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class MobileMenu extends React.Component{

    state = {
        open: false
    }

    componentDidMount(){
        
        //close the mobile menu when clicking on an anchor link
        const nodelist = document.querySelectorAll("ul.c_mobile-nav li a")

        for (let i = 0; i < nodelist.length; i++)
            nodelist[i].addEventListener("click", () => this.toggleMenu())
        
    }

    toggleMenu = () => {

        const logoElement = document.querySelector(".c_logo")

        if(!this.state.open){            
            logoElement.classList.add('invert')
        } else {
            logoElement.classList.remove('invert')
        }

        this.setState({
            open: !this.state.open
        })
        
    }

    render(){

        
        return(
            <>  
                <HamburgerIcon onClick={this.toggleMenu} open={this.state.open} />
                <nav className={`c_mobile-menu ${this.state.open ? 'c_open' : ''}`}>
                    <ul className="c_mobile-nav">
                        <li>
                            <AnchorLink offset='40' href="#riddling">RIDDLING</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink offset='20' href="#disgorgement">DISGORGEMENT</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink offset='40' href="#dosage">DOSAGE</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink offset='60' href="#contact">CONTACT US</AnchorLink>
                        </li>
                    </ul>
                    <ContactList />
                </nav>
            </>
        )
    }
}

export default MobileMenu